import client from "./client";

const getReportById = (id) => {
    return client
        .get(`/report/${id}`)
        .then((response) => {
            return response;
        })
        .catch((error) => error.response);
};

const getReports = async (filter) => {
    return client
        .get(`/reports`, filter)
        .then((response) => {
            return response.data;
        })
        .catch((error) => error.response);
};


const reportAPI = {
    getReportById,
    getReports,
};

export default reportAPI;

