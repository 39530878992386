<template>
    <div>
        <a-form>
            <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, paddingBottom: '40px' }">
                <a-row type="flex" :gutter="24">
                    <a-col :span="24" :lg="24" class="mb-12">
                        <h5 style="font-size: 20px; padding-top: 16px; padding-bottom: 16px;">Report details</h5>
                        <a-row :gutter="[24]">
                            <a-col :span="8">
                                <a-form-item class="fields mb-5" label="Type" :colon="false">
                                    <h6 v-for="(data, index) in report.type" :key="index"
                                        style="font-weight: 100; font-size: 13px;">{{ index + 1 }}. {{ data.name }}</h6>
                                </a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item class="fields mb-5" label="Contact Email" :colon="false">
                                    <h6 style="font-weight: 100;font-size: 13px;">{{ report.contact_email }}</h6>
                                </a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item class="fields mb-5" label="For Customer's Slug" :colon="false">
                                    <h6 style="font-weight: 100;font-size: 13px;">{{ report.for_customer.slug }}</h6>
                                </a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item class="fields mb-5" label="For Customer's Name" :colon="false">
                                    <h6 style="font-weight: 100;font-size: 13px;">{{ report.for_customer.name }}</h6>
                                </a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item class="fields mb-5" label="Created At" :colon="false">
                                    <h6 style="font-weight: 100;font-size: 13px;">{{ report.created_at }}</h6>
                                </a-form-item>
                            </a-col>
                            <a-col :span="24">
                                <a-form-item class="fields mb-10" label="Description" :colon="false">
                                    <h6 style="font-weight: 100;font-size: 13px;">{{
                                        report.description }}</h6>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </a-card>
        </a-form>
    </div>
</template>

<script>
import reportAPI from "../../api/report.js";

export default {
    data() {
        return {
            apiUrl: process.env.VUE_APP_BASE_URL,
            report: {
                type: [],
                contact_email: "",
                description: "",
                for_customer: { slug: "", name: "" },
                created_at: ""
            },
            form: this.$form.createForm(this, { name: "editReport" }),
        };
    },
    methods: {
        async getReportById(id) {
            return reportAPI.getReportById(id);
        },
    },
    async mounted() {
        if (this.$route.params.id !== undefined) {
            const response = await this.getReportById(this.$route.params.id);
            if (response.data.code == "SUCCESS") {
                this.report = response.data.data;
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.product-img {
    border-radius: 12px;
}
</style>
